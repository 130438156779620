// Load VexTab module.
vextab = require("vextab");
$ = require("jquery");
_ = require("underscore");
require("jquery-mousewheel")($);

$(function() {
  VexTab = vextab.VexTab;
  Artist = vextab.Artist;
  Renderer = vextab.Vex.Flow.Renderer;

  Artist.DEBUG = false;
  Artist.NOLOGO = true;
  VexTab.DEBUG = false;
  var score_scroll;
  var text = "";
  autoscroll = {
    isScrolling: false,
    current_scroll: 0,
    scrollInterval: setInterval(function() {
      if (autoscroll.isScrolling){
        if (artist.conductor.done_count == 8) {
          stopAutoScroll();
        }
        autoscroll.current_scroll = autoscroll.current_scroll + 16.5;
        score_scroll.scrollLeft(autoscroll.current_scroll);
      } else if (autoscroll.current_scroll != 0) {
        autoscroll.current_scroll = 0;
        score_scroll.scrollLeft(autoscroll.current_scroll);
      }
    } , 124)}

  // Create VexFlow Renderer from canvas element with id #boo
  renderer = new Renderer($('#boo')[0], Renderer.Backends.SVG);

  // Initialize VexTab artist and parser.
  artist = new Artist(10, 10, 24000, {scale: 0.75});
  vextab = new VexTab(artist);

  function startAutoScroll(){
    $(".preview_container").css("display","none");
    autoscroll.isScrolling = true;
  }

  function stopAutoScroll(){
    autoscroll.isScrolling = false;
    $(".preview_container").css("display","block");
  }

  function render() {
    try {
      vextab.reset();
      artist.reset();
      $.get("./score.txt", function(data) {
        text = data;
        vextab.parse(data);
        artist.render(renderer);
        artist.conductor.play_button.onMouseUp = function(event){
          artist.conductor.play();
          // Something's wrong with visualiztion on first play so play a second time for now
          artist.conductor.play();
          startAutoScroll();
        };
        artist.conductor.stop_button.onMouseUp = function(event){
          artist.conductor.stopPlayers();
          stopAutoScroll();
        };
      });
      $("#error").text("");
      score_scroll = $(".score_container")
    } catch (e) {
      console.log(e);
      $("#error").html(e.message.replace(/[\n]/g, '<br/>'));
    }
  }

  function validate_name(name){
    if (name.charAt(name.length - 1) == " ") name = name.substring(0, name.length - 1);
    var regex =  /^([A-ZÀ-Ú][a-zà-ú]*\s?)+$/;
    if (name == "") throw "¡Falta un nombre!";
    if (!regex.test(name)) throw "Nombres requieren mayusculas iniciales. \n"
                                       + "Ejemplo: Primera Segunda Tercera";
  }

  $("#paypal_buynow_button").click(function(e) {
      var first_name = "";
      var last_name = "";

      if ($("input[name='os1']").val() && $("input[name='os2']").val()) {
          first_name = $("input[name='os1']").val();
          last_name= $("input[name='os2']").val();
      }

      try {
        validate_name(first_name);
        validate_name(last_name);
      }
      catch (err) {
          $("#error").html(err.replace(/[\n]/g, '<br/>'));
          console.log("Throwing error: ", err);
          e.preventDefault();
          return;
      }
  });

  var busca_counter = 0;

  $("#busca_mi_nota").submit(function(e) {
      e.preventDefault();
      var name = "";
      if ($("#busca_mi_nota input[name='name']").val()) {
          name = $("#busca_mi_nota input[name='name']").val();
      }
//      try {
//        validate_name(name);
//      }
//      catch (err) {
//          $("#error").html(err.replace(/[\n]/g, '<br/>'));
//          return;
//      }
      var donor_name = name.replace(/ /g, "_");
      try{
        var matching_elems = $("svg").find("svg").find("[id*=" + donor_name + "]");
      } catch (err) {
        $("#error").html(err.replace(/[\n]/g, '<br/>'));
        return;
      }
      var elem = matching_elems[busca_counter];
      elem = $(elem);

      var elem_id = elem.attr("id").substring(3);
      console.log("ELEM_ID: ", elem_id);

      $(".score_container").scrollLeft(elem.position().left - 600);

      elem.find("path").css({"stroke" :"red", "fill":"red"});
      console.log("POSITION: ", elem.offset().top);
      $(".score_container").find("div." + elem_id).show()
        .css({"top":elem.offset().top - 380, "left":elem.position().left - 35});

      $("html, body").animate({
          scrollTop: 300
        }, 500);
      busca_counter += 1;
      if (busca_counter > matching_elems.length - 1) {
        busca_counter = 0;
      }

  });

  $(".score_view").mousewheel(function (e,d) {
    //If the score is playing disable mousewheel functionality
    if (artist.conductor.playing_now) {
      e.preventDefault();
    } else {
      var score_scroll = $(".score_container").scrollLeft();
      $(".score_container").scrollLeft(score_scroll - 10 * d);

      e.preventDefault();
    }
  });

  $("#blah").keyup(_.throttle(render, 250));
  render();
});
